<template>
  <v-container class="quotaholders-management">
    <h1 class="text-h5 mb-2">Cotistas</h1>

    <div class="mb-4 text-right">
      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => fetchData()"
      >
        <v-icon small left>
          mdi-refresh
        </v-icon>

        Atualizar
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => showFormDialog(null)"
        :disabled="!isElevatedUser"
      >
        <v-icon small left>
          mdi-plus
        </v-icon>

        Novo
      </v-btn>
    </div>

    <v-text-field
      label="Buscar..."
      solo
      hide-details
      class="mb-4"

      v-model="requestFilters.q"

      append-icon="mdi-magnify"
      @click:append="() => fetchData()"
      @keypress.enter="() => fetchData()"
    />

    <v-card>
      <quotaholders-table
        fixed-header
        dense

        must-sort
        sort-by="name"

        :items-per-page="-1"
        hide-default-footer

        :items="results"
        :loading="isFetchingData"
        :readonly="!isElevatedUser"

        @click:edit="(item) => showFormDialog(item)"
      />
    </v-card>

    <v-dialog persistent max-width="400" v-model="isFormModalOpen">
      <v-card>
        <v-card-title>
          {{ formDialogTitle }}
        </v-card-title>

        <v-card-text class="py-0">
          <quotaholder-form
            ref="upsertForm"
            discard-btn-text="Cancelar"
            :loading="isSendingInfo"
            :initial-data="currentItem || {}"
            @click:discard="() => hideFormDialog()"
            @submit="(fields) => submitUpsertForm(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';

import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValuesOnUrl } from '@/utils/router-utils';

import QuotaholderForm from './QuotaholderForm.vue';
import QuotaholdersTable from './QuotaholdersTable.vue';

export default {
  name: 'QuotaholdersManagement',

  components: {
    QuotaholderForm,
    QuotaholdersTable,
  },

  data: () => ({
    requestFilters: {
      q: loadValueFromUrl('q') ?? '',
    },

    results: [],
    isFetchingData: false,

    currentItem: null,
    isFormModalOpen: false,
    isSendingInfo: false,
  }),

  computed: {
    isEditingItem: (vm) => Object.keys(vm.currentItem ?? {}).length > 0,
    formDialogTitle: (vm) => (vm.isEditingItem ? 'Editar cotista' : 'Cadastrar cotista'),

    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler(newFilters) {
        saveValuesOnUrl({ ...newFilters });

        this.fetchData();
      },
    },

    isFormModalOpen(val) {
      if (!val) {
        this.currentItem = null;
      }
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
  },

  methods: {
    showFormDialog(target) {
      this.currentItem = target;
      this.isFormModalOpen = true;

      if (this.$refs.upsertForm) {
        this.$nextTick(() => this.$refs.upsertForm.discardChanges());
      }
    },

    hideFormDialog() {
      this.currentItem = null;
      this.isFormModalOpen = false;

      if (this.$refs.upsertForm) {
        this.$nextTick(() => this.$refs.upsertForm.discardChanges());
      }
    },

    fetchData: debounce(async function deboucedFetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.quotaholders.getQuotaholdersList({ params: this.requestFilters });
        this.results = data;
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;

        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isFetchingData = false;
      }
    }, 200),

    async submitUpsertForm(fields) {
      this.isSendingInfo = true;

      try {
        if (this.isEditingItem) {
          await api.quotaholders.updateQuotaholder(this.currentItem._id, fields);
        } else {
          await api.quotaholders.createQuotaholder(fields);
        }

        await this.fetchData();

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check',
          iconColor: 'success',
          title: `Cotista ${this.isEditingItem ? 'atualizado' : 'criado'}`,
          message: 'Os dados do cotista foram salvos com sucesso.',
        });

        this.hideFormDialog();
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;

        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isSendingInfo = false;
      }
    },
  },
};
</script>
