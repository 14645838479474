<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" :disabled="readonly" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <!-- <v-btn small icon color="error" :disabled="readonly" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn> -->

      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.taxIncidence="{ item }">
      {{ item.taxIncidence ? 'Sim' : 'Não' }}
    </template>
  </v-data-table>
</template>

<script>
import copyToClipboard from '@/utils/copy-to-clipboard';

export default {
  name: 'QuotaholdersTable',

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        align: 'center',
        sortable: false,
        groupable: false,
        width: '120px',
      },

      { value: 'name', text: 'Nome' },
      { value: 'houseFundName', text: 'Fundo' },
      { value: 'accountId', text: 'Account Id' },
      { value: 'taxIncidence', text: 'Incidência de impostos' },
    ],
  }),

  methods: {
    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>
