<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="loading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Nome"
            hint="Campo obrigatório"
            persistent-hint

            v-model="formFields.name"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12">
          <house-funds-selector
            label="Fundo"
            item-text="shortName"
            item-value="_id"
            v-model="formFields.houseFundId"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Account Id"
            v-model="formFields.accountId"
          />
        </v-col>

        <v-col cols="12">
          <v-switch
            class="mt-0"
            label="Incidência de impostos"
            v-model="formFields.taxIncidence"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="loading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || loading"
            :loading="loading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { requiredField } from '@/utils/validators';
import fillSchema from '@/utils/fill-schema';

import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';

const formSchema = {
  name: '',
  accountId: null,
  houseFundId: null,
  taxIncidence: false,
};

export default {
  name: 'QuotaholderForm',

  components: {
    HouseFundsSelector,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData),
    isFormValid: false,

    vRules: {
      requiredField,
    },
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText || 'Descartar alterações',
      submitBtn: vm.submitBtnText || 'Enviar',
    }),
  },

  methods: {
    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    submitForm() {
      this.$emit('submit', {
        ...this.formFields,
        accountId: this.formFields.accountId || undefined,
        houseFundId: this.formFields.houseFundId || undefined,
      });
    },
  },
};
</script>
